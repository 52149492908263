import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import ReactPlayer from "react-player";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import BanerMDD from "../../../components/BanerMDD/BanerMDD";

import locales from "../../../constants";

const BialaPerla = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 0,
  };
  const lang = "en";

  return (
    <Layout
      seo={{
        title: "Biała Perła",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/bialaperla-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 225deg, #ffcfe1, #fcc0d7, #fab6d0, #eda6c1, #e096b3 )",
        icons: "#fab6d0",
        navClass: "bialaperla",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/biala-perla/",
      }}
    >
      <PortfolioHeader
        name="bialaperla"
        height="70"
        video="https://wp.adream.pl/adream-content/bialaperla-spot.mp4"
      />

      <section className="container-fluid portfolio-intro" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1 style={{ color: "#fab6d0" }}>Biała Perła</h1>
              <ul>
                <li>Website</li>
                <li>Marketing campaign</li>
                <li>Catalogue</li>
                <li>Advertising spot</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner inner--divider">
              <p>
                We have created a number of projects for our Client, Czarna Góra
                Resort, as a part of development of a new investment project -
                the Biała Perła aparthotel. Our cooperation included designing
                and developing a the investment's website, creating a catalogue,
                as well as designing and running a marketing campaign, including
                the creation of an advertising spot.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--monitor scroll_wrapper--monitor-2">
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/bialaperla-full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--1">
        <div className="row">
          <div className="col-md-4">
            <h3>Website</h3>
            <p>
              We have designed a modern website, targeted at potential
              investors. We were the first in Poland to create an animated
              visualization of an aparthotel investment, which is displayed on
              the first screen (hero) of the new website.
            </p>
          </div>
          <div className="offset-md-1 col-md-7">
            <img
              src={require("../../../assets/img/portfolio/bialaperla-img-1.png")}
              alt=""
            />
            <img
              src={require("../../../assets/img/portfolio/bialaperla-img-2.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--2">
        <div className="bialaperla-section__row">
          <div className="bialaperla-section__col">
            <img
              src={require("../../../assets/img/portfolio/bialaperla-img-3.png")}
              alt=""
            />
          </div>
          <div className="bialaperla-section__col">
            <img
              src={require("../../../assets/img/portfolio/bialaperla-img-4.png")}
              alt=""
            />
          </div>
          <div className="bialaperla-section__col">
            <img
              src={require("../../../assets/img/portfolio/bialaperla-img-5.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--3">
        <div className="row">
          <div className="col-md-8 row__col">
            <img
              src={require("../../../assets/img/portfolio/bialaperla-img-6.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-3 row__col">
            <div className="inner">
              <h3>Marketing Campaign</h3>
              <p>
                Our internet marketing agency, WHEN, ran a campaign which
                significantly contributed to the sale of most investment
                apartments in several months.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--video">
        <div className="content-container">
          <div className="content-container__text">
            <h3>
              Advertising spot and a fully animated visualization of the
              facility
            </h3>
            <p>
              On the basis of the static visualization of the facility provided
              to us by the client, we have created a fully animated
              visualization and an advertising spot to be used in a promotional
              campaign, aimed at investors interested in buying investment
              apartments for rent.
            </p>
          </div>
          <div className="content-container__video">
            <ReactPlayer
              url="https://wp.adream.pl/adream-content/bialaperla/bialaperla-spot.mp4"
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
          <div className="content-container__animation">
            <ReactCompareSlider
              itemOne={
                <video
                  muted
                  autoPlay="autoplay"
                  loop={true}
                  height="100%"
                  playsInline
                >
                  <source
                    src="https://wp.adream.pl/adream-content/bialaperla/bialaperla-animation.mp4"
                    type="video/mp4"
                  />
                </video>
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={require("../../../assets/img/portfolio/bialaperla-animation-frame.jpg")}
                  alt=""
                />
              }
            />
          </div>
        </div>
      </section>

      <section className="container-fluid bialaperla-section bialaperla-section--4">
        <div className="col-md-4 offset-md-1">
          <h3>Catalogue</h3>
          <p>
            We have created a catalogue presenting the investment visualizations
            and containing all the key information that could be of interest to
            potential investors.
          </p>
        </div>
        <img
          src={require("../../../assets/img/portfolio/bialaperla-img-7.png")}
          alt=""
        />
      </section>

      <BanerMDD />

      <PortfolioNav data={nav} lang={lang} locales={locales[lang]} />
    </Layout>
  );
};
export default BialaPerla;
